import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  show: true,
  unfoldable: true,
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    updateShow(state, action) {
      state.show = action.payload
    },
    updateUnfoldable(state, action) {
      state.unfoldable = action.payload
    },
  },
})

export const { updateShow, updateUnfoldable } = sidebarSlice.actions

export default sidebarSlice.reducer
