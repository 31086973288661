import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
// import { useAuth0 } from '@auth0/auth0-react'
import { client } from '../../api/statsClient'

const initialState = {
  items: [],
  status: 'idle',
  error: null,
}

const statsSlice = createSlice({
  name: 'statsSlice',
  initialState: initialState,
  reducers: {
    reinitializeStats(state) {
      return initialState
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStats.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchStats.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.items = action.payload //state.items.concat(action.payload)
        console.log('ITEMS:: ', state.items)
      })
      .addCase(fetchStats.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const selectAllStats = (state) => state.stats.items

// export const insertEntry = (state, entry) => console.log(entry)

export const selectAllStatsForMonths = (payload) => (state) => {
  if (state.stats.status === 'succeeded') {
    var returnedItems = []
    state.stats.items.forEach((item) => {
      var filteredItem = {}
      filteredItem.data = item.data.filter(
        (data) =>
          data.month >= payload.from.month &&
          data.year >= payload.from.year &&
          data.month <= payload.to.month &&
          data.year <= payload.to.year,
      )
      filteredItem.label = item.label
      returnedItems.push(filteredItem)
    })
    // console.log(returnedItems)
    return returnedItems
  }
}

export const fetchStats = createAsyncThunk('events/fetchStats', async (param) => {
  console.log('stats query', param)
  const queryParams = {
    startDate: param.startDate,
  }
  const response = await client.fetchStats({
    headers: { Authorization: 'Bearer ' + param.token },
    query: queryParams,
  })
  return response.data
})

// export const { updateAttributes, eventTypeAdded, removeEventType, sort } = eventTypesSlice.actions
export const { reinitializeStats } = statsSlice.actions

export default statsSlice.reducer
