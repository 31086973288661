import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
// import { useAuth0 } from '@auth0/auth0-react'
import { client } from '../../api/etClient'

const initialState = {
  types: [],
  status: 'idle',
  error: null,
}

const eventTypesSlice = createSlice({
  name: 'eventTypesSlice',
  initialState: initialState,
  reducers: {
    eventTypeAdded: {
      reducer(state, action) {
        state.types.push(action.payload)
        state.types = state.types.sort((a, b) => {
          return a.eventType.toLowerCase() > b.eventType.toLowerCase()
            ? 1
            : b.eventType.toLowerCase() > a.eventType.toLowerCase()
            ? -1
            : 0
        })
      },
      // prepare(payload) {
      //   payload.id = nanoid()
      //   return payload
      // },
    },
    removeEventType(state, action) {
      console.log('types', state.types)
      state.types = state.types.filter((evType) => evType.id !== action.payload.id)
    },
    updateAttributes: {
      reducer(state, action) {
        const { id, itemAttributes } = action.payload
        const existingEvType = state.types.find((evType) => evType.id === id)
        if (existingEvType) {
          Object.keys(itemAttributes).forEach((attribute) => {
            existingEvType[attribute] = itemAttributes[attribute]
          })
        }
      },
    },
    sort: {
      reducer(state) {
        state.types = state.types.sort((a, b) => {
          return a.eventType.toLowerCase() > b.eventType.toLowerCase()
            ? 1
            : b.eventType.toLowerCase() > a.eventType.toLowerCase()
            ? -1
            : 0
        })
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEventTypes.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchEventTypes.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.types = state.types.concat(action.payload)
      })
      .addCase(fetchEventTypes.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const selectAllEventTypes = (state) => state.eventTypes.types

// .sort((a, b) => { return a.eventType > b.eventType ? 1 : b.eventType > a.eventType ? -1 : 0 })

export const selectEventTypesById = (state, eventTypeId) => {
  state.eventTypes.types.find((eventType) => eventType.id === eventTypeId)
}

export const fetchEventTypes = createAsyncThunk('events/fetchEventTypes', async (token) => {
  const response = await client.fetchEvents({ headers: { Authorization: 'Bearer ' + token } })
  return response.data
})

export const { updateAttributes, eventTypeAdded, removeEventType, sort } = eventTypesSlice.actions

export default eventTypesSlice.reducer
