import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'

import eventTypes from './components/features/eventTypesSlice'
import stats from './components/features/statsSlice'
import sidebar from './components/features/sidebarSlice'
import apps, { updateFitbit } from './components/features/appsSlice'
import workoutReducer from './components/redux/workoutSlice'
import { listenerMiddleware } from './components/features/localStorageMiddleware'

// // Create the middleware instance and methods
// const listenerMiddleware = createListenerMiddleware()

// // Add one or more listener entries that look for specific actions.
// // They may contain any sync or async logic, similar to thunks.
// listenerMiddleware.startListening({
//   actionCreator: updateFitbit,
//   effect: async (action, listenerApi) => {
//     // Run whatever additional side-effect-y logic you want here
//     console.log('Todo added: ', action.payload)
//   },
// })

export default configureStore({
  reducer: {
    eventTypes: eventTypes,
    stats: stats,
    sidebar: sidebar,
    workout: workoutReducer,
    apps: apps,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
})
