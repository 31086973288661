import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setNewWorkoutUrl } from '../redux/workoutSlice'

const Auth0ProviderWithHistory = ({ children, history }) => {
  const domain = 'dev-w2nvmnzr.eu.auth0.com'
  const clientId = '0Hx6RgMkoZATGfhcgP1ljTJ7E7rvSrhG'
  const audience = 'http://localhost:8080/'
  const dispatch = useDispatch()

  const onRedirectCallback = (appState) => {
    console.log('onRedirectCallback called', appState)
    if (appState && appState.appStateJSON) {
      try {
        const parsedState = JSON.parse(decodeURIComponent(appState.appStateJSON))
        console.log('parsedState', parsedState)
        if (parsedState.returnTo) {
          console.log('Redirecting to:', parsedState.returnTo)
          // Remove the leading '/#' if present
          const cleanPath = parsedState.returnTo.replace(/^\/#/, '')

          // Save newWorkoutUrl to Redux store
          if (parsedState.newWorkoutUrl) {
            dispatch(setNewWorkoutUrl(parsedState.newWorkoutUrl))
          }

          // Use replace instead of push to avoid adding to browser history
          history.replace(cleanPath, { newWorkoutUrl: parsedState.newWorkoutUrl })
          // Clean up the URL
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname + window.location.hash,
          )
        } else {
          console.log('No returnTo in parsedState, redirecting to root')
          history.replace('/')
        }
      } catch (error) {
        console.error('Error parsing appStateJSON:', error)
        history.replace('/')
      }
    } else {
      console.log('No appStateJSON in appState, redirecting to root')
      history.replace('/')
    }
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(Auth0ProviderWithHistory)
