import { createSlice } from '@reduxjs/toolkit'

const workoutSlice = createSlice({
  name: 'workout',
  initialState: {
    newWorkoutUrl: null,
    workouts: [],
  },
  reducers: {
    setNewWorkoutUrl: (state, action) => {
      state.newWorkoutUrl = action.payload
    },
    clearNewWorkoutUrl: (state) => {
      state.newWorkoutUrl = null
    },
    setWorkouts: (state, action) => {
      state.workouts = action.payload
    },
    addOrUpdateWorkout: (state, action) => {
      const index = state.workouts.findIndex((workout) => workout.id === action.payload.id)
      if (index !== -1) {
        // If workout exists, remove it from its current position
        state.workouts.splice(index, 1)
      }
      // Add the workout to the beginning of the array
      state.workouts.unshift({ ...action.payload, isNew: true })
    },
  },
})

export const { setNewWorkoutUrl, clearNewWorkoutUrl, setWorkouts, addOrUpdateWorkout } =
  workoutSlice.actions
export default workoutSlice.reducer
