import { createSlice } from '@reduxjs/toolkit'

const calculateInitial = () => {
  var localFitbit = JSON.parse(localStorage.getItem('fitbit-json'))
  console.log('fitbit::calculateInitial = ', localFitbit)
  return localFitbit || { data: { status: 'OFF', title: 'STATUS' } }
}

const initialState = {
  fitbit: calculateInitial(),
}

const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    updateFitbit(state, action) {
      state.fitbit = action.payload
    },
  },
})

export const { updateFitbit } = appsSlice.actions

export default appsSlice.reducer
