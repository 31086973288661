import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { updateFitbit } from './appsSlice'
import { RootState } from '../../index'

export const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: updateFitbit,
  effect: (action, listenerApi) => {
    // Run whatever additional side-effect-y logic you want here
    console.log('updateFitbit triggered: ', action.payload)
    localStorage.setItem('fitbit', JSON.parse(JSON.stringify(action.payload)))
    localStorage.setItem('fitbit-json', JSON.stringify(action.payload))
  },
})
